<template>
  <div>
    <div class="chart-header"></div>
    <md-button v-if="plotData.length > 0" @click="exportCSV">Download CSV</md-button>

    <plotly-graph v-if="plotData.length > 0" ref="plotlyGraph" :layout="layout" :plotData="plotData"
      @plotly-click="onPlotlyClick($event)" :plotlyConfig="plotlyConfig" />
  </div>
</template>

<script>
const icon1 = {
  width: 500,
  height: 600,
  path: "M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z",
};

import { exportCSVFile } from "@/utils/jsonToCsv";
import PlotlyGraph from "@/components/Monitoring/PlotlyGraph.vue";

import { PlotlyHelper } from "@/utils/multislotlineplot/plotlyhelper.js";

export default {
  name: "MultiSlotLineplot",
  components: {
    PlotlyGraph,
  },
  mixins: [],
  props: {
    displayer: Object,
    from: Date,
    to: Date,
    isLoading: Boolean,
    internalId: String,
    downtimes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    displayer: function () {
      clearInterval(this.interval);
      this.setComponent();
    },
    from: function () {
      if (this.timeoutBetweenRequests <= 0) {
        this.timeoutBetweenRequests = 1000;
        this.requestWithDelay();
      }
    },
    to: function () {
      if (this.timeoutBetweenRequests <= 0) {
        this.timeoutBetweenRequests = 1000;
        this.requestWithDelay();
      }
    },
  },
  data() {
    return {
      interval: null,
      layoutMonitoring: {},
      watchShallow: false,
      autoResize: true,
      firstRequest: true,
      timeoutBetweenRequests: 0,
      slotsData: [],
      myplotlyHelper: null,
      showDowntimeLines: false,
      mobileLayout: {
        // paper_bgcolor: "#42f5a1",
        // plot_bgcolor: "#ffefc7",
        margin: {
          b: 30,
          t: 30,
          l: 50,
          r: 50,
          pad: 0,
        },
        xaxis: {
          tickangle: 0,
        },
        autosize: true,
        height: 250,
        dragmode: false,
        font: {
          size: 10,
        },
      },
    };
  },
  methods: {
    setComponent() {
      const slots = this.displayer.slots;
      this.slotsData = [];
      slots.forEach((slot) => {
        this.makeRequest(slot.id)
          .then((slotdata) => {
            this.slotsData.push({
              ...slotdata,
              name: `${slot.alias}::${slot.deviceAlias}`,
              traceAttributes: (slot.traceAttributes ??= null),
            });
          })
          .then(() => {
            this.$emit("loaded");
          });
      });
    },
    makeRequest(slotId) {
      let from = this.from;
      let to = this.to;

      return this.$store
        .dispatch("monitoring/getMeasurements", {
          deviceSlotId: slotId,
          from: from,
          to: to,
        })
        .then((data) => {
          let measurements = data.measurements;
          this.firstRequest = false;
          const slotdata = this.formatSlotMeasurements(measurements);
          return slotdata;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatSlotMeasurements(measurements) {
      let slotData = {
        x: [],
        y: [],
        type: "scatter",
      };
      if (measurements.length > 0) {
        measurements.forEach((measurement) => {
          slotData.y.push(Number(measurement.calibrated_measurement));
          slotData.x.push(measurement.timestamp);
        });
      } else {
        slotData.isEmpty = true;
        slotData.showNoData = true;
      }
      return slotData;
    },
    requestWithDelay() {
      setTimeout(() => {
        if (this.timeoutBetweenRequests <= 0) {
          this.timeoutBetweenRequests = 0;
          clearInterval(this.interval);
          this.setComponent();
        } else {
          this.timeoutBetweenRequests = this.timeoutBetweenRequests - 100;
          this.requestWithDelay();
        }
      }, 100);
    },
    exportCSV(e) {
      e.preventDefault();
      const headers = {
        d: "Device",
        s: "Slot",
        z: "Epoch",
        x: "Timestamp",
        y: "Value",
      };

      const epoch = (date) => {
        return new Date(date).getTime();
      };

      let data = [];
      this.slotsData.forEach((slot) => {
        const slotAlias = slot.name.split("::");
        let slotData = [];
        slot.x.forEach((x, i) => {
          slotData.push({
            d: slotAlias[1],
            s: slotAlias[0],
            z: epoch(x),
            x: x,
            y: slot.y[i],
          });
        });
        data.push(...slotData);
      });
      exportCSVFile(headers, data, "Data");
    },
    onDowntimeButtonClick() {
      this.showDowntimeLines = !this.showDowntimeLines;
    },
  },
  created() {
    this.myplotlyHelper = new PlotlyHelper().addButton(
      "Show Downtimes",
      icon1,
      "down",
      this.onDowntimeButtonClick
    );
  },
  mounted() {
    this.setComponent();
  },
  computed: {
    plotData() {
      if (this.myplotlyHelper) {
        return this.myplotlyHelper
          .changeData(this.slotsData)
          .addDowntimes(this.downtimes)
          .downtimeMode(this.showDowntimeLines).plotData;
      } else {
        return [];
      }
    },
    layout() {
      if (this.myplotlyHelper && this.slotsData) {
        return this.myplotlyHelper
          .historicalBaseLayout(
            this.displayer.layoutConfiguration.title,
            this.displayer.layoutConfiguration.symbol,
            this.mobileLayout
          )
          .changeMargin(0.1, 0.1)
          .addAlternatingBackground(12).layout;
      } else {
        return {
          autosize: true,
        };
      }
    },
    plotlyConfig() {
      if (this.myplotlyHelper) {
        return this.myplotlyHelper.plotConfig;
      } else {
        return {
          responsive: true,
        };
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>