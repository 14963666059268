<template>
  <div>
    <tgl-card-header-mobile>Gráficos</tgl-card-header-mobile>
    <tgl-card-content-mobile>
      <date-picker
        class="date-picker-container"
        v-model="dateFilter"
        type="datetime"
        placeholder="Select datetime range"
        range
        :show-time-panel="showTimeRangePanel"
        :open.sync="open"
        @close="handleRangeClose"
        :input-attr="{ readonly: 'true' }"
      >
        <template v-slot:footer>
          <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
            {{ showTimeRangePanel ? "select date" : "select time" }}
          </button>
          <button class="mx-btn mx-btn-text" @click="closeDatePicker">
            Close
          </button>
        </template>
      </date-picker>
      <div
        class="show-plot-container"
        :style="`height: ${getWindowHeight - 396}px`"
        v-if="!showPlot"
      >
        <p>
          <v-icon color="primary">mdi-alert-circle-outline</v-icon>
          Girar dispositivo para ver gráficos con datos históricos o dentro de
          un rango de fechas
        </p>
      </div>
      <div
        class="row pt-5 pb-5 pr-1 pl-1"
        v-if="lineChartSlots.length > 0 && showPlot"
      >
        <template v-for="s in lineChartSlots">
          <div :key="s.id" class="col-12 mb-10">
            <multi-slot-lineplot
              :from="dateFilter[0]"
              :to="dateFilter[1]"
              :displayer="s"
              @loaded="isLoading = false"
              :downtimes="downtimeEvents"
            />
          </div>
        </template>
      </div>
    </tgl-card-content-mobile>
  </div>
</template>
<script>
// import { BreedingRhombusSpinner } from "epic-spinners";
import MultiSlotLineplot from "@/components/Mobile/Devices/Monitoring/MultiSlotLineplot.vue";
import TglCardContentMobile from "@/components/Mobile/Cards/TglCardContentMobile.vue";
import TglCardHeaderMobile from "@/components/Mobile/Cards/TglCardHeaderMobile.vue";
//test class
import { PlotlyHelper } from "/src/utils/multislotlineplot/plotlyhelper.js";
import { mockdata } from "@/utils/multislotlineplot/mockdata.js";
import { mapGetters } from "vuex";

export default {
  name: "mobile-historical",
  components: {
    // BreedingRhombusSpinner,
    MultiSlotLineplot,
    TglCardContentMobile,
    TglCardHeaderMobile,
  },
  props: {},
  mounted() {
    this.getDeviceDisplayers();
  },
  watch: {
    displayers() {
      this.divideDisplayers();
      this.getDowntimeEvents();
      this.set48HrsDefault();
    },
    dateFilter() {
      //this.isLoading = true;
    },
  },
  computed: {
    to() {
      return this.dateFilter[1];
    },
    from() {
      return this.dateFilter[0];
    },
    ...mapGetters("screen", ["getOrientation", "getWindowHeight"]),
    showPlot() {
      if (this.getOrientation === 90 || this.getOrientation === -90)
        return true;
      else return false;
    },
  },
  data: () => ({
    lineChartSlots: [],
    areaChartSlots: [],
    multiline: [],
    dateFilter: [],
    open: false,
    showTimePanel: false,
    showTimeRangePanel: false,
    isLoading: true,
    downtimeEvents: null,
    displayers: [],
  }),
  methods: {
    getDowntimeEvents() {
      const organizationId = this.$store.getters["user/user"].organization_id;
      return this.$store
        .dispatch("downtimeEvents/getDowntimeEvents", {
          identifier: this.displayers[0].slots[0].deviceIdentifier,
          from: this.from,
          to: this.to,
          organizationId: organizationId,
        })
        .then((data) => {
          const events =
            data.map((event) => {
              return event.timestamp;
            }) ?? [];
          this.downtimeEvents = events;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getDeviceDisplayers() {
      this.$store
        .dispatch("displayers/getDeviceDisplayers", this.$route.params.device)
        .then((data) => {
          this.displayers = data;
          this.divideDisplayers();
        });
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    handleRangeClose() {
      this.getDowntimeEvents();
      this.showTimeRangePanel = false;
    },
    closeDatePicker() {
      this.open = false;
    },
    divideDisplayers() {
      this.lineChartSlots = [];
      this.areaChartSlots = [];
      this.displayers.forEach((displayer) => {
        switch (displayer.displayModeId) {
          case 1:
            this.lineChartSlots.push(displayer);
            break;
          case 2:
            this.areaChartSlots.push(displayer);
            break;
          default:
            this.lineChartSlots.push(displayer);
            this.lineChartSlots.pop();
            break;
        }
      });
    },
    set48HrsDefault() {
      let from = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
      let to = new Date();

      this.dateFilter = [from, to];
    },
  },
};
</script>

<style lang="sass">
.show-plot-container
  display: flex
  justify-content: center
  align-items: center
  p
    font-size: 1.3rem
    font-weight: 300
    text-align: center
</style>